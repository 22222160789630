import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Contact from '../components/contact';
import GenerateReact from '../components/generate-react';
import { connect } from 'react-redux';
import { updatePath } from '../redux/actions';
import PropTypes from 'prop-types';
import { Divider, Typography } from '@material-ui/core';
const Home = (props) => {
  const data = useStaticQuery(query);
  React.useEffect(() => {
    props.reduxUpdatePath(props.uri);
  }, []);
  return (
    <>
      <GenerateReact data={data} />
      <Typography align="center" variant="h5" style={{ paddingBottom: 12 }}>
        Practicing in the Sioux City area.
      </Typography>
      <Contact />
      <Divider />
    </>
  );
};
const query = graphql`
  fragment Data on Node {
    ... on ContentfulTextBlock {
      title
      subTitle
      longText {
        raw
      }
      image {
        fluid(maxWidth: 250, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
    }
    ... on ContentfulInfoAccordionBlock {
      infoAccordionEntries {
        headerText
        content {
          raw
        }
      }
    }
    ... on ContentfulPageHead {
      mainText
      subText
      textColor
    }
    ... on ContentfulTestimonialBlock {
      testimonials {
        name
        testimonial {
          testimonial
        }
      }
    }
    ... on ContentfulTestimonial {
      name
      testimonial {
        testimonial
      }
    }
    ... on ContentfulHero {
      bigText
      btnText
      smallText
      link
    }
    ... on ContentfulSlideshow {
      name
      images {
        image {
          fixed(width: 300, quality: 80) {
            ...GatsbyContentfulFixed
          }
        }
      }
    }
    ... on ContentfulImage {
      image {
        fixed(width: 400, quality: 80) {
          ...GatsbyContentfulFixed
        }
      }
    }
    ... on ContentfulShortBio {
      shortBioText: bioText
      btnText
      name
      position
      link
      image {
        fixed(height: 200, resizingBehavior: SCALE) {
          ...GatsbyContentfulFixed
        }
      }
    }
  }
  fragment DataTypes on ContentfulPage {
    modules {
      ... on Node {
        ... on ContentfulSlantedSection {
          size
          topFlat
          bottomFlat
          variant
          modulesInSlanted {
            ...Data
          }
        }
        ...Data
      }
    }
  }

  query HomePage {
    page: contentfulPage(contentful_id: { eq: "5wqFm9XRjYbGxtWvZ3O1dE" }) {
      ...DataTypes
    }
  }
`;
Home.propTypes = {
  uri: PropTypes.string,
  reduxUpdatePath: PropTypes.func,
};
const mapDispatchToProps = (dispatch) => ({
  reduxUpdatePath: (data) => dispatch(updatePath(data)),
});
export default connect(null, mapDispatchToProps)(Home);
